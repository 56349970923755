import { Box } from '@qcx/ui';

export interface PageProps {
  children: React.ReactNode;
}

export const Page = ({ children }: PageProps) => (
  <Box as="main" css={{ pb: '$16' }}>
    {children}
  </Box>
);
